<template>
  <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
    <template #button-content>
      <button class="filter-button">
        <button class="filter-clear" v-if="notStarted || doing || done" @click.stop="clearFilter">
          <ClearFilterIcon />
        </button>
        {{ $t('label.status')}}: {{ getFilterLabels() }}
        <ArrowDownYellow />
      </button>
    </template>

    <b-dropdown-form>
      <div class="custom-control__container">
        <b-form-checkbox v-model="notStarted" @change="updateFilter"
          ><span> {{ $t('label.notStarted')}}</span><ProgressNotStarted
        /></b-form-checkbox>
      </div>
      <div class="custom-control__container">
        <b-form-checkbox v-model="doing" @change="updateFilter"
          ><span> {{ $t('label.inProgress')}}</span><ProgressDoing
        /></b-form-checkbox>
      </div>
      <div class="custom-control__container">
        <b-form-checkbox v-model="done" @change="updateFilter"
          ><span> {{ $t('label.passed')}}</span><ProgressDone
        /></b-form-checkbox>
      </div>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import ProgressDoing from '@/components/Icons/ProgressDoing.vue';
import ProgressDone from '@/components/Icons/ProgressDone.vue';
import ProgressNotStarted from '@/components/Icons/ProgressNotStarted.vue';
import ClearFilterIcon from '../Icons/ClearFilterIcon.vue';
import ArrowDownYellow from '../Icons/ArrowDownYellow.vue';

const statusFilters = {
  notStarted: { title: 'label.notStarted', value: 'notStarted' },
  doing: { title: 'label.inProgress', value: 'doing' },
  done: { title: 'label.passed', value: 'done' },
  notSelected: { title: 'label.all', value: 'notSelected' },
};
export default {
  name: 'StatusFilter',
  components: {
    ArrowDownYellow,
    ClearFilterIcon,
    ProgressDoing,
    ProgressDone,
    ProgressNotStarted,
  },
  props: {
    clearTrigger: {
      type: Number,
    },
  },
  data: () => ({
    notStarted: false,
    doing: false,
    done: false,
  }),
  methods: {
    getFilterLabels() {
      if (
        (this.notStarted && this.doing && this.done)
        || (!this.notStarted && !this.doing && !this.done)
      ) {
        return this.$t(statusFilters.notSelected.title);
      }
      let label = '';
      if (this.notStarted) label += `${this.$t(statusFilters.notStarted.title)}, `;
      if (this.doing) label += `${this.$t(statusFilters.doing.title)}, `;
      if (this.done) label += `${this.$t(statusFilters.done.title)}, `;
      return label;
    },
    clearFilter() {
      this.notStarted = false;
      this.doing = false;
      this.done = false;
      this.updateFilter();
    },
    updateFilter() {
      const filters = [];
      if (this.notStarted) filters.push('notStarted');
      if (this.doing) filters.push('doing');
      if (this.done) filters.push('done');
      this.$emit('updateFilter', { statusFilter: filters });
    },
  },
  watch: {
    clearTrigger() {
      if (this.notStarted || this.doing || this.done) {
        this.clearFilter();
      }
    },
  },
};
</script>
