<template>
  <div class="stat-dropdown" v-click-outside="closeDropdown">
    <div class="download-stat" @click="showDropdown = !showDropdown">
      <MoreVerticalIcon :class="{ show: showDropdown }" />
    </div>
    <div class="stat-dropdown__dropdown" :class="{ show: showDropdown, wide: isDeepDropdown }">
      <div class="variants">
        <div v-if="isDeepDropdown">
          <div
            v-for="item in radioItems"
            class="stat-action"
            :key="item.index"
            @click="requestStatFile(item.method)"
          >
            {{ item.label }}
          </div>
        </div>
        <div v-else>
          <div class="stat-action" @click="downloadItemStat">
            {{ $t('label.downloadStatistics') }}
          </div>
          <div class="stat-action" v-if="type === 'task'" @click="downloadAnswers">
            {{ $t('label.downloadAnswers') }}
          </div>
        </div>
        <div class="actions" v-if="loading">
          <div class="dropdown-loader">
            <Loader disableScale />
            <div class="dropdown-loader__label">{{ $t('label.fileInProgress') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '@/components/Loader/Loader.vue';
import ActivityService from '@/services/activity.service';
import MoreVerticalIcon from './Icons/MoreVerticalIcon.vue';

const statisticsMode = {
  single: 'activity',
  withChildren: 'with children',
  recursively: 'recursively',
};

export default {
  name: 'StatDropdown',
  components: {
    Loader,
    MoreVerticalIcon,
  },
  props: {
    type: {
      type: String,
      default: 'program',
    },
    id: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showDropdown: false,
    loading: false,
  }),
  computed: {
    radioItems() {
      if (this.type === 'program') {
        return [
          {
            label: this.$t('label.onlyProgram'),
            method: statisticsMode.single,
          },
          {
            label: this.$t('label.onlyProgramAndCourses'),
            method: statisticsMode.withChildren,
          },
          {
            label: this.$t('label.allProgramContent'),
            method: statisticsMode.recursively,
          },
        ];
      }
      if (this.type === 'course') {
        return [
          {
            label: this.$t('label.onlyCourse'),
            method: statisticsMode.single,
          },
          {
            label: this.$t('label.courseAndGroups'),
            method: statisticsMode.withChildren,
          },
          {
            label: this.$t('label.allCourseContent'),
            method: statisticsMode.recursively,
          },
        ];
      }
      return [
        {
          label: this.$t('label.quizOnly'),
          method: statisticsMode.single,
        },
        {
          label: this.$t('label.quizAndQuestions'),
          method: statisticsMode.withChildren,
        },
      ];
    },
    isDeepDropdown() {
      return this.type !== 'material' && this.type !== 'video' && this.type !== 'task';
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    requestStatFile(method) {
      this.loading = true;
      const { programId } = this.$route.params;
      switch (method) {
        case 'activity':
          ActivityService.getActivityStatisticsFile({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
              this.makeWarningToast();
            })
            .finally(() => (this.loading = false));
          break;

        case 'with children':
          ActivityService.getActivityStatisticsFileWithChildren({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
              this.makeWarningToast();
            })
            .finally(() => (this.loading = false));
          break;

        case 'recursively':
          ActivityService.getActivityStatisticsFileWithChildrenRecursively({
            programId,
            id: this.id,
            locale: this.$root.$i18n.locale,
          })
            .then((response) => {
              this.fetchBlobToXlsx(response.data);
            })
            .catch((error) => {
              console.log(error);
              this.makeWarningToast();
            })
            .finally(() => (this.loading = false));
          break;
        default:
      }
    },
    fetchBlobToXlsx(blob) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'statistics.xlsx');
      } else {
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob([blob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
        );
        downloadLink.download = 'statistics.xlsx';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },
    closeDropdown() {
      if (this.loading) {
        return;
      }
      this.showDropdown = false;
    },

    downloadAnswers() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const { programId } = this.$route.params;
      ActivityService.getActivityAnswersFile({
        programId,
        id: this.id,
        locale: this.$root.$i18n.locale,
      })
        .then((response) => {
          this.fetchBlobToXlsx(response.data, 'answers.xlsx');
        })
        .catch((error) => {
          console.log(error);
          this.makeWarningToast();
        })
        .finally(() => (this.loading = false));
    },
    downloadItemStat() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const { programId } = this.$route.params;
      ActivityService.getActivityStatisticsFile({
        programId,
        id: this.id,
        locale: this.$root.$i18n.locale,
      })
        .then((response) => {
          this.fetchBlobToXlsx(response.data, 'statistics.xlsx');
        })
        .catch((error) => {
          console.log(error);
          this.makeWarningToast();
        })
        .finally(() => (this.loading = false));
    },
    makeWarningToast() {
      this.setToaster({
        type: 'toast-warning',
        toast: {
          title: this.$t('errorMessages.somethingWentWrong'),
          body: this.$t('errorMessages.tryAgain'),
        },
      });
    },
  },
};
</script>
