<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click')">
    <path
      d="M16.7992 7.2002L7.19922 16.8002"
      stroke="#F2F2F2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.19922 7.2002L16.7992 16.8002"
      stroke="#F2F2F2"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'WhiteCrossIconMedium',
};
</script>
