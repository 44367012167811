<template>
  <div class="search-input">
    <b-form-group :id="id" :class="{ active: isFocused, disabled: disabled }"
      ><SearchIcon />
      <b-form-input
        :id="id"
        :ref="id"
        v-model="inputValue"
        type="text"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="handleBlur"
        @focus="isFocused = true"
        @change="(v) => $emit('change', v)"
        @keyup.enter="(v) => $emit('enter', v)">
      </b-form-input>
      <WhiteCrossIconMedium v-if="inputValue && !disabled" @click="clearSearch" />
    </b-form-group>
  </div>
</template>

<script>
import WhiteCrossIconMedium from './Icons/WhiteCrossIconMedium.vue';
import SearchIcon from './Icons/SearchIcon.vue';

export default {
  name: 'SearchInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { WhiteCrossIconMedium, SearchIcon },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    inputValue: {
      get() {
        if (this.value === 0) {
          return '';
        }
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
  },
  methods: {
    handleInput() {
      if (!this.isFocused) {
        this.isFocused = true;
        this.$nextTick(() => {
          this.$refs[this.id].focus();
        });
      }
    },
    handleBlur(e) {
      this.isFocused = false;
      this.$emit('change', e.target.value);
    },
    clearSearch() {
      this.inputValue = '';
    },
  },
};
</script>
