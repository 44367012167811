<template>
  <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
    <template #button-content>
      <button class="filter-button">
        <button class="filter-clear" v-if="isBanned || !isAllowed" @click.stop="clearFilter">
          <ClearFilterIcon />
        </button>
        {{ $t('label.access') }}: {{ getFilterLabels() }}
        <ArrowDownYellow />
      </button>
    </template>

    <b-dropdown-form>
      <div class="custom-control__container">
        <b-form-checkbox v-model="isAllowed" @change="updateFilter"
          ><span>{{ $t('label.accessOpen') }}</span></b-form-checkbox
        >
      </div>
      <div class="custom-control__container" @change="updateFilter">
        <b-form-checkbox v-model="isBanned"
          ><span>{{ $t('label.accessDenied') }}</span></b-form-checkbox
        >
      </div></b-dropdown-form
    >
  </b-dropdown>
</template>

<script>
import ArrowDownYellow from '../Icons/ArrowDownYellow.vue';
import ClearFilterIcon from '../Icons/ClearFilterIcon.vue';

const accessFilters = {
  isBanned: { title: 'label.accessDenied', value: 'isBanned' },
  isAllowed: { title: 'label.accessOpen', value: 'isAllowed' },
  notSelected: { title: 'label.all', value: 'notSelected' },
};

export default {
  name: 'AccessFilter',
  components: { ArrowDownYellow, ClearFilterIcon },
  props: {
    clearTrigger: {
      type: Number,
    },
  },
  data: () => ({
    isBanned: false,
    isAllowed: true,
  }),
  methods: {
    getFilterLabels() {
      if ((!this.isBanned && !this.isAllowed) || (this.isBanned && this.isAllowed)) {
        return this.$t(accessFilters.notSelected.title);
      }
      if (this.isBanned && !this.isAllowed) {
        return this.$t(accessFilters.isBanned.title);
      }
      if (!this.isBanned && this.isAllowed) {
        return this.$t(accessFilters.isAllowed.title);
      }
    },
    clearFilter() {
      this.isBanned = false;
      this.isAllowed = true;
      this.updateFilter();
    },
    updateFilter() {
      const filters = [];
      if (this.isBanned) filters.push('isBanned');
      if (this.isAllowed) filters.push('isAllowed');
      this.$emit('updateFilter', { accessFilter: filters });
    },
  },
  watch: {
    clearTrigger() {
      if (this.isBanned || !this.isAllowed) {
        this.clearFilter();
      }
    },
  },
};
</script>
