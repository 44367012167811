<template>
  <div>
    <div class="lms-table__header">
      <div class="header__top">
        <SearchInput
          id="statistic-search"
          :disabled="!!selected.length"
          :placeholder="$t('label.enterEmailOrFio')"
          :value.sync="searchPromt"
        />
      </div>
      <div class="header__bottom">
        <div
          class="select-all cursor-pointer"
          @click="handleSelectAll"
          v-if="$route.name === 'Program'"
        >
          <RadioButton
            isTypeCheckBox
            @change="handleSelectAll"
            :checked="isAllSelected"
            :isSending="!sortedUsers.length"
          />
          <span>{{ $t('label.selectAll') }}</span>
        </div>
        <div class="d-flex" v-if="selected.length">
          <div class="manage-access" @click="handleMultipleBan">
            <RedCrossCircleIcon />
            <span>{{ $t('label.closeAccessToProgram') }}</span>
          </div>
          <div class="manage-access" @click="handleMultipleUnban">
            <GreenFlagCircleIcon />
            <span>{{ $t('label.openAccessToProgram') }}</span>
          </div>
          <div></div>
        </div>
        <StatisticFilter
          v-show="!selected.length"
          :showAccessFilter="$route.name === 'Program'"
          showStatusFilter
          showDateFilter
          showSort
          @filterChange="handleFilterChange"
        />
      </div>
    </div>
    <b-table
      class="lms-table program-statistic scroll"
      id="table-user"
      :items="sortedUsers"
      :fields="fields"
      primary-key="username"
      borderless
      responsive
      :busy.sync="loading"
      :tbody-transition-props="transProps"
      stacked="sm"
      :fixed="false"
      :no-provider-paging="true"
      :current-page="currentPage"
      :per-page="perPage"
      ref="table"
      @row-selected="onRowSelected"
    >
      <template #cell(checkbox)="data">
        <RadioButton
          isTypeCheckBox
          @change="handleUserSelect(data.item)"
          :checked="isSelected(data.item)"
        />
      </template>
      <template #cell(userNameAndLatName)="data">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </template>
      <template #cell(status)="data">
        <div class="d-flex items-center align-items-center justify-content-between">
          <div class="mr-2 status-container">
            <ProgressStatus :status="status(data.item)" iconPosition="left" />
          </div>
        </div>
      </template>
      <template #cell(created_at)="data">
        <div>
          {{ formatDateCreation(data.item.created_at) }}
        </div>
      </template>
      <template #cell(time)="data">
        {{ secondsFormat(data.item.time_spent) }}
      </template>
      <template #cell(points)="data"> {{ data.item.score }}/{{ max_score }} </template>
      <template #cell(access)="data">
        <LmsSwitch
          :key="switcherKey"
          :value="!data.item.ban_status"
          size="large"
          withIcon
          @update:value="(bool) => handleBanSwitch(data, bool)"
        />
      </template>
      <!--      <template #table-busy>-->
      <!--        <div class="container f-center">-->
      <!--          <Loader />-->
      <!--        </div>-->
      <!--      </template>-->
    </b-table>
    <TablePagination
      v-show="sortedUsers.length > 20"
      :length="sortedUsers.length"
      @goToPage="goToPage"
      @setPerPage="setItemsPerPage"
    />
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination.vue';
import ActivityService from '@/services/activity.service';
import LmsSwitch from '@/components/LmsSwitch.vue';
import ProgressStatus from '@/components/ProgressStatus.vue';
import RadioButton from '@/components/Buttons/RadioButton.vue';
import RedCrossCircleIcon from '@/components/Icons/RedCrossCircleIcon.vue';
import GreenFlagCircleIcon from '@/components/Icons/GreenFlagCircleIcon.vue';
import TablePagination from '@/components/TablePagination.vue';
import {
  doing, done, notStarted, unavailable, rejected,
} from '@/utils/constants';
import paginationMixin from '@/mixins/pagination.mixin';
import { mapActions } from 'vuex';
import isWithinRange from 'date-fns/is_within_range';
import isValid from 'date-fns/is_valid';
import SearchInput from './SearchInput.vue';
import StatisticFilter from './StatisticFilter.vue';
import { ActivityStatuses } from '../utils/statusConstants';

export default {
  name: 'TableUsers',
  props: {
    id: {
      type: String,
      default: '',
    },
    activeWidgetSort: {
      type: String,
      default: 'All',
    },
    statisticMeta: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ProgressStatus,
    LmsSwitch,
    RadioButton,
    RedCrossCircleIcon,
    GreenFlagCircleIcon,
    StatisticFilter,
    TablePagination,
    SearchInput,
  },
  mixins: [paginationMixin],
  data() {
    return {
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: this.$route.name !== 'Program' ? 'd-none' : '',
          tdClass: this.$route.name !== 'Program' ? 'd-none' : '',
        },
        {
          key: 'userNameAndLatName',
          label: this.$t('label.user'),
        },
        {
          key: 'username',
          label: 'E-mail',
        },
        {
          key: 'status',
          label: this.$t('label.status'),
        },
        {
          key: 'created_at',
          label: this.$t('label.startDate'),
        },
        {
          key: 'time',
          label: this.$t('label.timeSpent'),
        },
        {
          key: 'points',
          label: this.$t('label.points'),
        },
        {
          key: 'access',
          label: this.$t('label.access'),
          thClass: this.$route.name !== 'Program' ? 'd-none' : '',
          tdClass: this.$route.name !== 'Program' ? 'd-none' : '',
        },
      ],
      users: [],
      selected: [],
      loading: false,
      sortBy: 'username',
      sortDesc: false,
      currentPage: 1,
      perPage: 20,
      isRequestBusy: false,
      max_score: 0,
      isAnswerFileBusy: false,
      isStatFileBusy: false,
      userBanList: [],
      switcherKey: 1,
      currentFilter: null,
      searchPromt: '',
    };
  },
  computed: {
    transProps() {
      // Transition name
      return this.activeWidgetSort === 'All' ? { name: 'flip-list' } : null;
    },
    isAllSelected() {
      return this.selected.length === this.sortedUsers.length && this.sortedUsers.length !== 0;
    },
    sortedUsers() {
      if (!this.currentFilter && !this.searchPromt) return this.users;

      let sortedUsers = this.users;

      if (this.searchPromt) {
        sortedUsers = sortedUsers.filter((item) => (this.checkSearchMatch(item, this.searchPromt)));
      }

      if (this.currentFilter?.statusFilter) {
        // eslint-disable-next-line  max-len
        sortedUsers = sortedUsers.filter((item) => this.checkStatusFilterMatch(item, this.currentFilter.statusFilter));
      }

      if (this.currentFilter?.accessFilter) {
        // eslint-disable-next-line  max-len
        sortedUsers = sortedUsers.filter((item) => this.checkAccessFilterMatch(item, this.currentFilter.accessFilter));
      }

      if (this.currentFilter?.dateRange) {
        // eslint-disable-next-line  max-len
        sortedUsers = sortedUsers.filter((item) => this.checkDateRangeFilterMatch(item, this.currentFilter.dateRange));
      }

      if (this.currentFilter?.selectedSort) {
        sortedUsers = sortedUsers.sort((a, b) => {
          if (this.currentFilter?.selectedSort === 'emailAsc') {
            return a.username.localeCompare(b.username);
          }
          if (this.currentFilter?.selectedSort === 'emailDesc') {
            return b.username.localeCompare(a.username);
          }
          if (this.currentFilter?.selectedSort === 'userAsc') {
            const aFullName = `${a.firstName.trim()} ${a.lastName.trim()}`;
            const bFullName = `${b.firstName.trim()} ${b.lastName.trim()}`;
            return aFullName.localeCompare(bFullName);
          }
          if (this.currentFilter?.selectedSort === 'userDesc') {
            const aFullName = `${a.firstName.trim()} ${a.lastName.trim()}`;
            const bFullName = `${b.firstName.trim()} ${b.lastName.trim()}`;
            return bFullName.localeCompare(aFullName);
          }
          if (this.currentFilter?.selectedSort === 'timeAsc') {
            return a.time_spent - b.time_spent;
          }
          if (this.currentFilter?.selectedSort === 'timeDesc') {
            return b.time_spent - a.time_spent;
          }
          if (this.currentFilter?.selectedSort === 'scoreAsc') {
            return a.score - b.score;
          }
          if (this.currentFilter?.selectedSort === 'scoreDesc') {
            return b.score - a.score;
          }
          if (this.currentFilter?.selectedSort === 'dateAsc') {
            if (!a.created_at || !isValid(new Date(a.created_at))) return 1;
            if (!b.created_at || !isValid(new Date(b.created_at))) return -1;
            return (
              new Date(a.created_at) - new Date(b.created_at)
            );
          }
          if (this.currentFilter?.selectedSort === 'dateDesc') {
            return (
              new Date(b.created_at) - new Date(a.created_at)
            );
          }
          return 0;
        });
      }
      return sortedUsers;
    },
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    checkAccessFilterMatch(item, filterList) {
      if (filterList.includes('isAllowed') && !item.ban_status) return true;
      if (filterList.includes('isBanned') && item.ban_status) return true;
      return false;
    },
    checkStatusFilterMatch(item, filterList) {
      if (filterList.includes('notStarted') && item.status === ActivityStatuses.notStarted) return true;
      if (filterList.includes('doing') && item.status === ActivityStatuses.doing) return true;
      if (
        filterList.includes('done')
        && (item.status === ActivityStatuses.done || item.status === ActivityStatuses.rejected)
      ) return true;
      return false;
    },
    checkDateRangeFilterMatch(item, dateRange) {
      return isWithinRange(
        new Date(item.created_at).setHours(0, 0, 0, 0),
        new Date(dateRange.startDate).setHours(0, 0, 0, 0),
        new Date(dateRange.endDate).setHours(0, 0, 0, 0),
      );
    },
    checkSearchMatch(user, searchPromt) {
      return (
        user.username.includes(searchPromt.toLowerCase())
        || `${user.firstName.trim()} ${user.lastName.trim()}`
          .toLowerCase()
          .includes(searchPromt.toLowerCase())
      );
    },
    async getUsers() {
      const { programId } = this.$route.params;
      const id = this.id ? this.id : this.$route.params.programId;

      await ActivityService.getActivityById(id)
        .then(({ data }) => {
          this.max_score = data.max_score ?? 0;
          this.userBanList = data.student_ids_ban_list;
        })
        .catch(console.log);

      return ActivityService.getActivityStudentsStatistics({ programId, id })
        .then(({ data }) => {
          if (data) {
            // const allowedStudents = data.filter((item) => !banList.includes(item._id));
            const allowedStudents = data.map((element) => {
              const user = element;
              user.ban_status = !!this.userBanList.includes(user._id);
              return user;
            });
            this.$emit('allowedStudents', allowedStudents);
            this.users = allowedStudents;
          }
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    handleBanSwitch(user, data) {
      if (this.isRequestBusy) {
        return;
      }
      if (!data) {
        this.removeUser(user);
      } else {
        this.unbanUser(user);
      }
    },
    handleUserSelect(user) {
      if (!this.isSelected(user)) {
        this.selected.push(user._id);
      } else {
        this.selected.splice(
          this.selected.findIndex((x) => x === user._id),
          1,
        );
      }
    },
    handleSelectAll() {
      if (this.selected.length !== this.sortedUsers.length) {
        this.selected = this.sortedUsers.map((user) => user._id);
      } else {
        this.selected = [];
      }
    },
    removeUser(student) {
      const id = this.id ? this.id : this.$route.params.programId;
      const { programId } = this.$route.params;
      this.isRequestBusy = true;
      ActivityService.banMultipleStudents({
        programId,
        id,
        students: [student.item._id],
      })
        .then(() => {
          this.getUsers();
          this.increaseSwitcherCounter();
          this.isRequestBusy = false;
        })
        .catch(this.errorMessage);
    },
    unbanUser(student) {
      const id = this.id ? this.id : this.$route.params.programId;
      const { programId } = this.$route.params;
      this.isRequestBusy = true;
      ActivityService.unbanMultipleStudents({
        programId,
        id,
        students: [student.item._id],
      })
        .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
          const isAllBanned = unbannedStudentsIds.length === 1;
          if (!isAllBanned) {
            this.setToaster({
              type: 'toast-warning',
              toast: {
                title: this.$t('header.attention'),
                body: this.$t('supportText.reachedUserLimit'),
              },
            });
          }
          this.isRequestBusy = false;
          this.getUsers();
          this.increaseSwitcherCounter();
        })
        .catch(this.errorMessage);
    },
    errorMessage(error) {
      const errMessage = error?.response?.data?.error;
      this.setToaster({
        type: 'toast-warning',
        toast: {
          title: this.$t('errorMessages.somethingWentWrong'),
          body: errMessage || this.$t('errorMessages.tryAgain'),
        },
      });
    },
    fetchMultipleIds(param) {
      if (param === 'unban') {
        return this.selected
          .filter((element) => this.userBanList.includes(element));
      }
      return this.selected
        .filter((element) => !this.userBanList.includes(element));
    },
    handleMultipleBan() {
      const filteredIds = this.fetchMultipleIds('ban');
      if (filteredIds.length) {
        const id = this.id ? this.id : this.$route.params.programId;
        const { programId } = this.$route.params;
        this.isRequestBusy = true;
        ActivityService.banMultipleStudents({
          programId,
          id,
          students: filteredIds,
        })
          .then(() => {
            this.getUsers();
            this.increaseSwitcherCounter();
            this.isRequestBusy = false;
          })
          .catch(this.errorMessage);
      } else {
        this.getUsers();
        this.increaseSwitcherCounter();
        this.isRequestBusy = false;
      }
    },
    handleMultipleUnban() {
      const filteredIds = this.fetchMultipleIds('unban');
      if (filteredIds.length) {
        const id = this.id ? this.id : this.$route.params.programId;
        const { programId } = this.$route.params;
        this.isRequestBusy = true;
        ActivityService.unbanMultipleStudents({
          programId,
          id,
          students: filteredIds,
        })
          .then(({ unbanned_students_ids: unbannedStudentsIds }) => {
            const isAllBanned = unbannedStudentsIds.length === filteredIds.length;
            if (!isAllBanned) {
              this.setToaster({
                type: 'toast-warning',
                toast: {
                  title: this.$t('header.attention'),
                  body: this.$t('supportText.notAllWasUnbaned'),
                },
              });
            }
            this.getUsers();
            this.increaseSwitcherCounter();
            this.isRequestBusy = false;
          })
          .catch(this.errorMessage);
      } else {
        this.getUsers();
        this.increaseSwitcherCounter();
      }
    },
    sortItems(ctx, items) {
      let filtered = items;
      if (ctx.filter === 'Done') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.done);
      } else if (ctx.filter === 'Doing') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.doing);
      } else if (ctx.filter === 'Not-started') {
        filtered = filtered.filter((s) => s.status === ActivityStatuses.notStarted);
      }
      return filtered.sort((a, b) => {
        if (ctx?.sortDesc) {
          return b.username.localeCompare(a.username);
        }
        return a.username.localeCompare(b.username);
      });
    },
    status(item) {
      if (item.ban_status) {
        return unavailable;
      }
      if (item.status === ActivityStatuses.done) {
        return done;
      }

      if (item.status === ActivityStatuses.rejected && this.statisticMeta?.type === 'quiz') {
        return {
          value: 'rejected',
          label: 'label.rejectedMasculine',
        };
      }

      if (item.status === ActivityStatuses.rejected) {
        return rejected;
      }

      if (item.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
    isSelected(user) {
      return this.selected.includes(user._id);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    increaseSwitcherCounter() {
      setTimeout(() => {
        this.switcherKey += 1;
      }, 300);
    },
    secondsFormat(seconds) {
      return new Date(seconds * 1000).toISOString().slice(11, 19);
    },
    handleFilterChange(e) {
      this.currentFilter = e;
    },
    formatDateCreation(date) {
      if (!date || !isValid(new Date(date))) return '—';
      const timeToSet = new Date(date);
      return new Intl.DateTimeFormat(this.$i18n?.locale === 'en' ? 'en' : 'ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(timeToSet);
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
