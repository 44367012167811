<template>
  <div class="statistic-filter">
    <AccessFilter
      v-if="showAccessFilter"
      :clearTrigger="clearTrigger"
      @updateFilter="handleFilterUpdate"
    />
    <StatusFilter
      v-if="showStatusFilter"
      :clearTrigger="clearTrigger"
      @updateFilter="handleFilterUpdate"
    />
    <DateDropdown
      v-if="showDateFilter"
      :clearTrigger="clearTrigger"
      :title=" $t('label.dateOfComplete')"
      :subTitle="$t('supportText.chooseStartDateOrDateRange')"
      @updateFilter="handleFilterUpdate"
    />
    <SortDropdown v-if="showSort" :clearTrigger="clearTrigger" @updateFilter="handleFilterUpdate" />
    <ClearFilterButton @click="clearAllFilters" v-if="filtersIsDirty" />
  </div>
</template>
<script>
import DateDropdown from './Filters/DateDropdown.vue';
import AccessFilter from './Filters/AccessFilter.vue';
import StatusFilter from './Filters/StatusFilter.vue';
import SortDropdown from './Filters/SortDropdown.vue';
import ClearFilterButton from './Buttons/ClearFilterButton.vue';

export default {
  name: 'StatisticFilter',
  components: {
    DateDropdown,
    AccessFilter,
    StatusFilter,
    SortDropdown,
    ClearFilterButton,
  },
  props: {
    showAccessFilter: {
      type: Boolean,
      default: false,
    },
    showStatusFilter: {
      type: Boolean,
      default: false,
    },
    showDateFilter: {
      type: Boolean,
      default: false,
    },
    showSort: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentFilter: { selectedSort: 'emailAsc', accessFilter: ['isAllowed'] },
    clearTrigger: 0,
  }),
  computed: {
    filtersIsDirty() {
      return (
        (this.currentFilter?.accessFilter?.length !== 1
        || this.currentFilter?.accessFilter[0] !== 'isAllowed')
        || this.currentFilter?.statusFilter?.length
        || this.currentFilter?.dateRange?.startDate
        || this.currentFilter?.dateRange?.endDate
        || this.currentFilter.selectedSort !== 'emailAsc'
      );
    },
  },
  methods: {
    handleFilterUpdate(e) {
      this.currentFilter = { ...this.currentFilter, ...e };
      this.updateFilter();
    },
    updateFilter() {
      const filterPayload = {};
      if (this.currentFilter?.accessFilter?.length) {
        filterPayload.accessFilter = this.currentFilter.accessFilter;
      }

      if (this.currentFilter?.statusFilter?.length) {
        filterPayload.statusFilter = this.currentFilter.statusFilter;
      }

      if (this.currentFilter?.dateRange?.startDate && this.currentFilter?.dateRange?.endDate) {
        filterPayload.dateRange = this.currentFilter.dateRange;
      }

      filterPayload.selectedSort = this.currentFilter.selectedSort;

      this.$emit('filterChange', filterPayload);
    },
    clearAllFilters() {
      this.clearTrigger += 1;
    },
  },
  mounted() {
    this.updateFilter();
  },
};
</script>
