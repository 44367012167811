<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1964 14.6083H15.3628L15.0673 14.3234C15.7268 13.5574
      16.2087 12.655 16.4787 11.6809C16.7486 10.7068 16.8 9.6851
      16.629 8.68886C16.1331 5.7555 13.6851 3.41303 10.7306
      3.05428C9.69192 2.92287 8.63694 3.03082 7.64639 3.36987C6.65584
      3.70891 5.75597 4.27006 5.01566 5.01038C4.27534 5.75069
      3.71419 6.65056 3.37515 7.64111C3.0361 8.63166 2.92815
      9.68664 3.05956 10.7253C3.41831 13.6798 5.76078 16.1278
      8.69414 16.6237C9.69038 16.7947 10.7121 16.7434 11.6862
      16.4734C12.6603 16.2034 13.5626 15.7215 14.3287 15.0621L14.6136
      15.3575V16.1911L19.0981 20.6755C19.5307 21.1082 20.2376
      21.1082 20.6703 20.6755C21.1029 20.2429 21.1029 19.536 20.6703
      19.1033L16.1964 14.6083ZM9.86537 14.6083C7.23801 14.6083
      5.11713 12.4875 5.11713 9.86009C5.11713 7.23273 7.23801
      5.11185 9.86537 5.11185C12.4927 5.11185 14.6136 7.23273
      14.6136 9.86009C14.6136 12.4875 12.4927 14.6083 9.86537 14.6083Z"
      fill="#F2F2F2"
    />
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon',
};
</script>
