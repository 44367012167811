<template>
  <div>
    <div class="teacher-statistic__widgets statistic-widget container">
      <div v-for="widget in widgets" :key="widget.variant">
        <StatisticCard
          :variant="widget.variant"
          :count="widget.count"
          :percent="widget.percent"
        />
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <TableUsers
        v-if="showStatisticDelayed"
        class="container"
        :id="id"
        :statisticMeta="statisticMeta"
        @allowedStudents="allowedStudents"
      />
    </transition>
  </div>
</template>

<script>
import StatisticCard from '@/components/Cards/StatisticCard.vue';
import TableUsers from '@/components/TableUsers.vue';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'StatisticsStudents',
  components: {
    StatisticCard,
    TableUsers,
  },
  props: {
    statisticMeta: {
      type: Object,
      default: () => ({}),
    },
    showStatistic: {
      type: Boolean,
      required: true,
    },
    showStatisticDelayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    widgets: [
      {
        variant: 'All',
        count: 0,
        percent: 0,
        isActive: true,
      },
      {
        variant: 'Not-started',
        count: 0,
        percent: 0,
        isActive: false,
      },
      {
        variant: 'Doing',
        count: 0,
        percent: 0,
        isActive: false,
      },
      {
        variant: 'Done',
        count: 0,
        percent: 0,
        isActive: false,
      },
    ],
  }),
  computed: {
    id() {
      if (this.statisticMeta?.id) {
        return this.statisticMeta?.id;
      }
      return this.$route.params.programId;
    },
  },
  methods: {
    allowedStudents(students) {
      this.students = students;
      const statisticCounter = students.reduce(
        (acc, currentStudent) => {
          if (currentStudent.status === ActivityStatuses.done
          || currentStudent.status === ActivityStatuses.rejected) {
            acc.completed += 1;
          } else if (currentStudent.status === ActivityStatuses.doing) {
            acc.doing += 1;
          } else {
            acc.notStarted += 1;
          }

          return acc;
        },
        {
          completed: 0,
          doing: 0,
          notStarted: 0,
          all: students.length,
        },
      );

      this.setAllStudentWidget(statisticCounter);
      this.setNotStartedWidget(statisticCounter);
      this.setDoingWidget(statisticCounter);
      this.setDoneWidget(statisticCounter);
    },
    setAllStudentWidget(stats) {
      this.widgets[0].count = stats.all;
    },
    setNotStartedWidget(stats) {
      this.widgets[1].count = stats.notStarted;
      this.widgets[1].percent = stats.notStarted
        ? Math.round((100 * stats.notStarted) / stats.all)
        : 0;
    },
    setDoingWidget(stats) {
      this.widgets[2].count = stats.doing;
      this.widgets[2].percent = stats.doing ? Math.round((100 * stats.doing) / stats.all) : 0;
    },
    setDoneWidget(stats) {
      this.widgets[3].count = stats.completed;
      this.widgets[3].percent = stats.completed
        ? Math.round((100 * stats.completed) / stats.all)
        : 0;
    },

    resetWidgets() {
      this.widgets = this.widgets.map((w, index) => {
        const clone = w;
        clone.count = 0;
        clone.percent = 0;
        // eslint-disable-next-line no-unused-expressions
        index === 0 ? (clone.isActive = true) : (clone.isActive = false);
        return clone;
      });
    },
  },
  watch: {
    showStatistic(bool) {
      // reset widgets state to show animation on widgets on init if statistic id is different
      if (!bool) {
        this.resetWidgets();
      }
    },
  },
};
</script>
